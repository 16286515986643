import * as React from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import stewart from "../../images/lineup/stewartlee.jpg";

function StewartLee() {
  return (
    <Container className="info" maxWidth="xl">
      <div className="info-content">
        <div className="text-section">
          <Typography className="title" variant="h6">
            STEWART LEE – BRIGHTON PSYCH FEST
          </Typography>
        </div>
        <div className="text-section stewart-lee-container">
          <Typography className="text">
            Brighton Psych Fest asked me to host and program a stage, which is
            reckless of them as the last thing I curated, the 2016 Prestatyn All
            Tomorrow’s Parties, went bust. And no, I didn’t get paid either.
            Nonetheless, here’s a mind-melting day out of acid-hued action from
            five of my favourite acts, with minimal onstage interruptions from
            me. I’ve tried to skew the selection towards Brighton and Hastings
            talent because sometimes we don’t always acknowledge the geniuses on
            our own doorsteps.
          </Typography>
          <img src={stewart} className="stewart-img" alt="stewart lee" />
        </div>
        <div className="text-section">
          <Typography className="text">
            <Link
              to="https://open.spotify.com/artist/2UWJVpm81yeOuzFWmaYEAF?si=TD4eYsWiQU6FF9tZzvtEgw"
              target="_blank"
            >
              The Bevis Frond
            </Link>
          </Typography>
          <Typography className="text">
            Nick Saloman’s enduring underground legends enter their
            thirty-eighth year utterly undimmed, surging inland from the
            seafronts of the South Coast with paisley power-pop anthems Teenage
            Fanclub would’ve clobbered them for, short sharp shocks of
            Wipers-influenced melodic hardcore, and vast electrical storms of
            duelling guitars that sound like the ‘70s Miles Davis band battling
            The Quicksilver Messenger Service. The Bevis Frond are a silver
            thread running through my life. Please play High In A Flat.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">
            <Link
              to="https://open.spotify.com/artist/30EilmonVXftR1pWoALezP?si=q_oW8d9jQZGQqewwUsrkVg"
              target="_blank"
            >
              The Physics House Band
            </Link>
          </Typography>
          <Typography className="text">
            Like punk never happened, the Brighton trio offers ugly-beautiful
            instrumental progressive rock that aging King Crimson fans think
            no-one can play anymore, leavened with a pastoral psychedelic
            seasoning, a Mondrian mathematical complexity, and a sly sense of
            its own audacious absurdity.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">
            <Link
              to="https://open.spotify.com/artist/1cYp6MXwirSdYPk7EcwqtE?si=Cwyq7z4HSqu1t44kmjBhig"
              target="_blank"
            >
              Alison Cotton
            </Link>
          </Typography>
          <Typography className="text">
            Formerly a folk rocker with The Left Outsides, the Sunderland
            multi-instrumentalist currently essays vast John Cale-coloured
            string-drones with incantatory overtones, wrenching ritual music for
            our ruined land from the overbowed strings of her viola and the
            breathy bellows of the harmonium. A Nico for now.{" "}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">
            <Link
              to="https://open.spotify.com/artist/0GCgX6rhXhwKvLhcZke4F9?si=rGpQymHIRc-HIjyFQISbZA"
              target="_blank"
            >
              Eliza Skelton
            </Link>
          </Typography>
          <Typography className="text">
            Like a cross between Steve Nicks and a Stygian witch that can see
            into your soul, this long-term low-key local legend finally goes
            solo, and delivers an immersive and smoothly cinematic epic
            acid-folk music of widescreen grandeur, wyrd enough to thrill
            weirdoes, yet classy enough to subversively snaffle the casual
            consumer.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">
            <Link to="https://soundcloud.com/secluded-bronte" target="_blank">
              Secluded Bronte
            </Link>
          </Typography>
          <Typography className="text">
            People who hate my stand-up often deny it even constitutes ‘comedy’.
            Doubtless many would wonder if the work of the veteran improvisatory
            trio Secluded Bronte should be considered ‘music’. Instruments may
            be involved, perhaps furniture, maybe even words, or all three in a
            chance collision of calculated heterogenity. All that is certain –
            time will pass, and something will happen. Start your day in a right
            state.{" "}
          </Typography>
        </div>

        <div className="text-section">
          <Typography className="title" variant="h6">
            Contact
          </Typography>
          <Typography className="text">info@brightonpsychfest.com</Typography>
        </div>
      </div>
    </Container>
  );
}
export default StewartLee;
