import { Button, Container } from "@mui/material";
import lineup from "../../images/assets/roblineup.png";
// import lineupMobile from "../../images/assets/lineupFinal.png";
 
function Home() {
  return (
    <div className="home">
      <div className="home-lineup">
        <Container maxWidth="xl">
          <div className="lineup-container lineup-container-desktop">
            <img src={lineup} className="lineup-image" alt="lineup" />
          </div>
          {/* <div className="lineup-container lineup-container-mobile">
            <img src={lineupMobile} className="lineup-image" alt="lineup" />
          </div> */}
        </Container>
      </div>

      <div className="home-tickets">
        <Container maxWidth="xl">
          <div className="home-tickets-container">
            <Button
              href="https://www.seetickets.com/event/brighton-psych-fest/various-brighton-venues/2924482"
              color="warning"
              size="large"
              variant="contained"
              sx={{
                marginTop: "5px",
                fontSize: "28px",
                padding: "10px 40px",
                textAlign: "center",
              }}
              target="_blank"
              className="ticket-header-button"
            >
              Buy Tickets
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}
export default Home;
