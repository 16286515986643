import * as React from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import concorde2 from "../../images/stage-times/CONCORDE.png";
import greenDoorStore from "../../images/stage-times/GREEN DOOR STORE.png";
import komeBase from "../../images/stage-times/KOME BASE.png";
import komediaStudio from "../../images/stage-times/KOMEDIA STUDIO.png";
import theArch from "../../images/stage-times/THE ARCH.png";
import theHopeAndRuin from "../../images/stage-times/THE HOPE & RUIN.png";
import thePrinceAlbert from "../../images/stage-times/THE PRINCE ALBERT.png";

function StageTimes() {
  return (
    <Container className="info" maxWidth="xl">
      <div className="info-content">
        <div className="text-section">
          <Typography className="title" variant="h6">
            Stage Times
          </Typography>
        </div>
        <div className="stage-times-images">
          <div className="image-section">
            <img
              src={concorde2}
              className="stage-img"
              alt="concorde 2 stage times"
            />
          </div>
          <div className="image-section">
            <img
              src={theArch}
              className="stage-img"
              alt="the arch stage times"
            />
          </div>
          <div className="image-section">
            <img
              src={komeBase}
              className="stage-img"
              alt="kome base stage times"
            />
          </div>
          <div className="image-section">
            <img
              src={komediaStudio}
              className="stage-img"
              alt="komedia studio stage times"
            />
          </div>
          <div className="image-section">
            <img
              src={greenDoorStore}
              className="stage-img"
              alt="green door store stage times"
            />
          </div>

          <div className="image-section">
            <img
              src={thePrinceAlbert}
              className="stage-img"
              alt="prince albert stage times"
            />
          </div>
          <div className="image-section">
            <img
              src={theHopeAndRuin}
              className="stage-img"
              alt="hope and ruin stage times"
            />
          </div>
        </div>

        <div className="text-section">
          <Typography className="title" variant="h6">
            Contact
          </Typography>
          <Typography className="text">info@brightonpsychfest.com</Typography>
        </div>
      </div>
    </Container>
  );
}
export default StageTimes;
