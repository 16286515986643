import * as React from "react";
import Container from "@mui/material/Container";
import logo from "../../../images/logo/logomain.png";
import { Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <Container maxWidth="xl" className="footer-main">
        <div className="footer-container">
          <div className="footer-container-section">
            <Link className="footer-logo-link" to="/">
              <img src={logo} className="footer-logo" alt="logo" />
            </Link>
            <Button
              href="https://www.seetickets.com/event/brighton-psych-fest/various-brighton-venues/2924482"
              color="primary"
              size="large"
              variant="contained"
              className="ticket-header-button"
              sx={{
                fontSize: "16px",
                margin: "20px 0",
                textAlign: "center",
              }}
              target="_blank"
            >
              Buy Tickets
            </Button>
          </div>
          <div className="footer-container-section">
            <div className="footer-social-container">
              <h3>Get in touch</h3>
              <a
                className="email-link"
                href="mailto:info@brightonpsychfest.com"
                target="_blank"
                rel="noreferrer"
              >
                info@brightonpsychfest.com
              </a>

              <div className="social-icons">
                <a
                  href="https://www.facebook.com/brightonpsychfest"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/brightonpsychfest"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://twitter.com/btnpsychfest"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://open.spotify.com/user/31mx3gpvalidcwwyadrlmr3jryb4?si=36269f674d41408a"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="black"
                  >
                    <path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.057,16.406c-0.188,0-0.282-0.094-0.47-0.188 c-1.411-0.847-3.198-1.317-5.079-1.317c-1.035,0-2.163,0.188-3.104,0.376c-0.188,0-0.376,0.094-0.47,0.094 c-0.376,0-0.658-0.282-0.658-0.658c0-0.376,0.188-0.658,0.564-0.658c1.223-0.282,2.446-0.47,3.763-0.47 c2.163,0,4.139,0.564,5.832,1.505c0.188,0.188,0.376,0.282,0.376,0.658C16.621,16.218,16.339,16.406,16.057,16.406z M17.091,13.773 c-0.188,0-0.376-0.094-0.564-0.188c-1.599-1.035-3.857-1.599-6.302-1.599c-1.223,0-2.352,0.188-3.198,0.376 c-0.188,0.094-0.282,0.094-0.47,0.094c-0.47,0-0.753-0.376-0.753-0.753c0-0.47,0.188-0.658,0.658-0.847 c1.129-0.282,2.258-0.564,3.857-0.564c2.634,0,5.079,0.658,7.149,1.881c0.282,0.188,0.47,0.47,0.47,0.753 C17.844,13.396,17.562,13.773,17.091,13.773z M18.314,10.762c-0.188,0-0.282-0.094-0.564-0.188 c-1.881-1.035-4.609-1.693-7.243-1.693c-1.317,0-2.728,0.188-3.951,0.47c-0.188,0-0.282,0.094-0.564,0.094 C5.428,9.54,5.051,9.069,5.051,8.505c0-0.564,0.282-0.847,0.658-0.941C7.215,7.188,8.814,7,10.507,7 c2.916,0,5.926,0.658,8.278,1.881c0.282,0.188,0.564,0.47,0.564,0.941C19.255,10.386,18.878,10.762,18.314,10.762z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="footer-container-section">
            <div className="subscribe-inner">
              <h3>Join our mailing list</h3>
              <Button
                href="https://4f4dfef9.sibforms.com/serve/MUIFAD7Tttpm_WsNn8vV8vHdJP-2msA_zuk5vEcsOVjlQM38rLtM46WhgjQHDtj_kZIssdt-hUzkymvcPZ_0onqe0GMODdH-8xEnMDWAjKHichprh_qeTx7GVY6bOkAPE5hnURCW2F4akMiqsssVJS3XiYKQ-T4nfYMZauBPtMufKvRV-rjAl381V9cD2XwvBSMtJu1QXGKBXnTc"
                color="primary"
                size="large"
                variant="contained"
                className="ticket-header-button"
                sx={{
                  fontSize: "16px",
                  margin: "20px 0",
                  textAlign: "center",
                }}
                target="_blank"
              >
                Subscribe
              </Button>
            </div>
          </div>
        </div>
        <div className="footer-section-bottom">
          <div className="footer-bottom">
            <p>© 2024 Brighton Psych Fest</p>
          </div>
        </div>
      </Container>
    </footer>
  );
}
export default Footer;
