import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LineUpCard from "./line-up-card";

const artists = [
  {
    name: "Pigs x7",
    sort: "pigs",
    link: "https://www.pigsx7.com/",
    img: "pigsx7.jpeg",
  },
  {
    name: "Temples",
    sort: "temples",
    link: "https://www.templestheband.com/",
    img: "temples.jpeg",
  },
  {
    name: "NewDad",
    sort: "newdad",
    link: "https://www.newdad.live/",
    img: "newdad.jpg",
  },
  {
    name: "Juniore",
    sort: "juniore",
    link: "https://heyjuniore.bandcamp.com/",
    img: "juniore.jpg",
  },
  {
    name: "LA LUZ",
    sort: "la luz",
    link: "https://laluzband.com/",
    img: "laluz.jpg",
  },
  {
    name: "Bdrmm",
    sort: "bdrmm",
    link: "https://bdrmm.bandcamp.com/",
    img: "bdrmm.jpeg",
  },
  {
    name: "The Bug Club",
    sort: "bug club",
    link: "https://thebugclub.bandcamp.com/",
    img: "thebugclub.jpg",
  },
  {
    name: "Thus Love",
    sort: "thus love",
    link: "https://thuslove.bandcamp.com/",
    img: "thuslove.jpeg",
  },
  {
    name: "Divorce",
    sort: "divorce",
    link: "https://divorcehq.co.uk",
    img: "divorce.jpeg",
  },
  {
    name: "Holly Macve",
    sort: "holly nacve",
    link: "https://hollymacvemusic.com",
    img: "hollymacve.jpeg",
  },
  {
    name: "Molly Payton",
    sort: "molly payton",
    link: "https://www.mollypayton.com",
    img: "mollypayton.jpg",
  },
  {
    name: "Ladylike",
    sort: "ladylike",
    link: "https://www.instagram.com/ladylike.band/",
    img: "ladylike.webp",
  },
  {
    name: "GENN",
    sort: "genn",
    link: "https://www.genntheband.com/home",
    img: "genn.jpeg",
  },
  {
    name: "Porchlight",
    sort: "porchlight",
    link: "https://porchlightporchlight.bandcamp.com/",
    img: "porchlight.jpeg",
  },
  {
    name: "Hutch",
    sort: "hutch",
    link: "https://bandofhutch.bandcamp.com/",
    img: "hutch.jpeg",
  },
  {
    name: "Karma Sheen",
    sort: "karma sheen",
    link: "https://karmasheen.bandcamp.com/",
    img: "karmasheen.jpg",
  },
  {
    name: "Plantoid",
    sort: "plantoid",
    link: "https://plantoidworld.bandcamp.com/",
    img: "plantoid.jpeg",
  },
  {
    name: "Das Koolies",
    sort: "das koolies",
    link: "https://daskoolies.co.uk/",
    img: "daskoolies.jpg",
  },
  {
    name: "EBBB",
    sort: "ebbb",
    link: "https://www.instagram.com/ebbbbbbbbbbbbb_/",
    img: "ebbb.jpeg",
  },
  {
    name: "GIRL AND GIRL",
    sort: "girl and girl",
    link: "https://open.spotify.com/artist/6tRT8DibGiNSkXViROkIBn",
    img: "girlandgirl.jpeg",
  },
  {
    name: "The Horrors",
    sort: "horrors",
    link: "https://www.thehorrors.co.uk/",
    img: "horros.jpeg",
  },
  {
    name: "KLAUSS JOHANN GROBE",
    sort: "klauss johann grobe",
    link: "https://open.spotify.com/artist/4m8zzr6DNSW4tkuyGHvF7h?si=bkf0imU5TF6vJ3uG5TfJvg",
    img: "klauss.jpeg",
  },
  {
    name: "LUTALO",
    sort: "lutalo",
    link: "https://open.spotify.com/artist/0gSmzBSSMT1yJanwTK16Ln?si=fPFmeQ5ERhuXaVCrxSDdNw",
    img: "lutalo.jpg",
  },
  {
    name: "PASTEL",
    sort: "pastel",
    link: "https://open.spotify.com/artist/45Tlc11sM7ZCtAkCA9Nrx9?si=RZO2dBhrRjWrgLPDWR9Xxw",
    img: "pastel.jpg",
  },
  {
    name: "TTSSFU",
    sort: "ttssfu",
    link: "https://open.spotify.com/artist/4u0g598Mtg9ch4HgEP2DFG?si=z-ld4Ql1QPOo7nUm3n4UKw",
    img: "ttssfu.jpg",
  },
  {
    name: "THE ORCHESTRA (FOR NOW)",
    sort: "orchestra",
    link: "https://www.instagram.com/theorchestrafornow/",
    img: "ochestra.jpg",
  },
  {
    name: "SCALER (FKA SCALPING)",
    sort: "scaler",
    link: "https://scalerband.com/",
    img: "scaler.jpeg",
  },
  {
    name: "Alison Cotton",
    sort: "alison cotton",
    link: "https://open.spotify.com/artist/1cYp6MXwirSdYPk7EcwqtE?si=Cwyq7z4HSqu1t44kmjBhig",
    img: "alison.jpg",
  },
  {
    name: "The Bevis Frond",
    sort: "bevis frond",
    link: "https://open.spotify.com/artist/2UWJVpm81yeOuzFWmaYEAF?si=TD4eYsWiQU6FF9tZzvtEgw",
    img: "bevis.jpg",
  },
  {
    name: "The Physics House Band",
    sort: "physics house band",
    link: "https://open.spotify.com/artist/30EilmonVXftR1pWoALezP?si=q_oW8d9jQZGQqewwUsrkVg",
    img: "physics.jpg",
  },
  {
    name: "Eliza Skelton",
    sort: "eliza skelton",
    link: "https://open.spotify.com/artist/0GCgX6rhXhwKvLhcZke4F9?si=rGpQymHIRc-HIjyFQISbZA",
    img: "eliza.jpg",
  },
  {
    name: "Secluded Bronte",
    sort: "secluded bronte",
    link: "https://fforddallan.bandcamp.com/track/elbow-grease",
    img: "bronte.jpeg",
  },
  {
    name: "Stewart Lee",
    sort: "stewart lee",
    link: "https://www.stewartlee.co.uk/",
    img: "stewartlee.jpg",
  },
  {
    name: "A.SAVAGE",
    sort: "A SAVAGE",
    link: "https://a-savage.com/",
    img: "savage.jpg",
  },
  {
    name: "DAIISTAR",
    sort: "DAIISTAR",
    link: "https://www.daiistar.com/",
    img: "daiistar.jpg",
  },
  {
    name: "GEORDIE GREEP",
    sort: "GEORDIE GREEP",
    link: "https://www.instagram.com/emperorgreep/?hl=en",
    img: "geordiegreep.jpeg",
  },
  {
    name: "OPAL MAG",
    sort: "OPAL MAG",
    link: "https://www.instagram.com/opalmag_music/",
    img: "opalmag.jpeg",
  },
];

const sortedArtists = artists.sort((a, b) => a.sort.localeCompare(b.sort));

function LineUp() {
  return (
    <Container className="LineUp" maxWidth="xl">
      <Box sx={{ flexGrow: 1, flexWrap: "wrap", display: { xs: "flex" } }}>
        {sortedArtists.map((artist) => (
          <LineUpCard key={artist.name} artist={artist} />
        ))}
      </Box>
    </Container>
  );
}
export default LineUp;
