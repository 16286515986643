import * as React from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

function Info() {
  return (
    <Container className="info" maxWidth="xl">
      <div className="info-content">
        <div className="text-section">
          <Typography className="title" variant="h6">
            BRIGHTON PSYCH FEST 2024 – FAQ / INFO
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Where
          </Typography>
          <Typography className="text">
            Multi-venue festival taking place at:
          </Typography>

          <Typography className="text">Concorde 2</Typography>
          <Typography className="text">
            286A Madeira Dr, Brighton and Hove, Brighton BN2 1EN
          </Typography>
          <Typography className="text">The Arch</Typography>
          <Typography className="text">
            187-193 Kings Rd, Brighton BN1 1NB
          </Typography>
          <Typography className="text">Hope & Ruin</Typography>
          <Typography className="text">
            11-12 Queens Rd, Brighton and Hove, Brighton BN1 3WA
          </Typography>
          <Typography className="text">Komedia</Typography>
          <Typography className="text">
            44-47 Gardner St, Brighton and Hove, Brighton BN1 1UN
          </Typography>
          <Typography className="text">The Green Door Store</Typography>
          <Typography className="text">
            2,3,4 Trafalgar Arches, Brighton and Hove, Brighton BN1 4FQ
          </Typography>
          <Typography className="text"> The Prince Albert</Typography>
          <Typography className="text">
            48 Trafalgar St, Brighton and Hove, Brighton BN1 4ED
          </Typography>

          <Typography className="text">
            More partner venue announcements to follow!
          </Typography>
          <Typography className="text">
            Ticket gains access to all venues. ***
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            When
          </Typography>
          <Typography className="text">Friday 30th August 2024.</Typography>
          <Typography className="text">3pm-3am.</Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Live music line-up
          </Typography>
          <Typography className="text">
            {"Click "} <Link to="/line-up">here</Link>{" "}
            {" to visit line-up A-Z page."}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Accessibility
          </Typography>
          <Typography className="text">
            {"Click "}{" "}
            <Link to="https://www.hope.pub/access/" target="_blank">
              here
            </Link>{" "}
            {" for The Hope & Ruin’s accessibility info."}
          </Typography>
          <Typography className="text">
            {"Click "}{" "}
            <Link to="https://www.concorde2.co.uk/faq" target="_blank">
              here
            </Link>{" "}
            {" for Concorde 2’s accessibility info."}
          </Typography>
          <Typography className="text">
            {"Click "}{" "}
            <Link
              to="https://www.komedia.co.uk/brighton/visit/accessibility/"
              target="_blank"
            >
              here
            </Link>{" "}
            {" for Komedia’s accessibility info."}
          </Typography>
          <Typography className="text">
            {"Click "}{" "}
            <Link
              to="https://thegreendoorstore.co.uk/venue-info/access/"
              target="_blank"
            >
              here
            </Link>{" "}
            {" for The Green Door Store’s accessibility info."}
          </Typography>
          <Typography className="text">The Prince Albert</Typography>
          <Typography className="text">
            There is no wheelchair access at this venue, please contact
            info@brightonpsychfest.com for any specific access questions.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Tickets
          </Typography>
          <Typography className="text">Limited £30 tickets.**</Typography>
          <Typography className="text">£20 early birds - SOLD OUT.</Typography>
          <Typography className="text">
            {"Click "}{" "}
            <Link
              target="_blank"
              to="https://www.seetickets.com/event/brighton-psych-fest/various-brighton-venues/2924482"
            >
              here
            </Link>{" "}
            {" to purchase."}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Wristband Collection x Festival Hub
          </Typography>
          <Typography className="text">
            The Wristband exchange will be announced at a later date
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Ages
          </Typography>
          <Typography className="text">
            {
              "Concorde 2, Komedia Main, Komedia Studio - 14+ with U16s accompanied by an adult until 11pm - 18+ from 11pm onwards."
            }
          </Typography>
          <Typography className="text">
            {
              "The Arch - 14+ with U16s accompanied by an adult until 10pm - 18+ from 11pm onwards."
            }
          </Typography>
          <Typography className="text">
            {"The Hope & Ruin & The Green Door Store - 18+ at all times."}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Getting to the festival
          </Typography>
          <Typography className="text">
            The festival will take place across The Hope & Ruin, Concorde 2 and
            Komedia.
          </Typography>
          <Typography className="text">
            Your wristband gains access to all stages.***
          </Typography>
          <Typography className="text">
            The venues are accessible by foot - parking in Brighton is limited
            so walking or travelling by public transport where possible is
            highly recommended.
          </Typography>
          <Typography className="text">
            Closest Train Station: Queens Rd, Brighton and Hove, Brighton BN1
            3XP
          </Typography>
          <Typography className="text">
            National Express Coach Stop: Brighton Pool Valley Coach Station, BN1
            1NJ
          </Typography>
          <Typography className="text">
            Venues are served by multiple bus routes around the city including:
            1, 1A, 5B, 12X + more - plan your journey using the Brighton and
            Hove Buses route planner
          </Typography>
          <Typography className="text">
            https://www.buses.co.uk/plan-your-journey
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">* Line-up subject to change</Typography>
          <Typography className="text">** plus booking fees</Typography>
          <Typography className="text">
            *** Venue capacities are based on first come first serve so please
            arrive early for specific acts.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Contact
          </Typography>
          <Typography className="text">info@brightonpsychfest.com</Typography>
        </div>
      </div>
    </Container>
  );
}
export default Info;
